<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h4>{{ $t('registration.request_sent_title') }}</h4>
    </div>
    <div class="panel-body">
      <p>{{ $t('registration.request_sent') }}</p>
    </div>
  </div>
</template>

<script src="./registration_request_sent.js"></script>
