<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h4>{{ $t('registration.awaiting_email_confirmation_title') }}</h4>
    </div>
    <div class="panel-body">
      <p>{{ $t('registration.awaiting_email_confirmation') }}</p>
    </div>
  </div>
</template>

<script src="./awaiting_email_confirmation.js"></script>
